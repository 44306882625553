import React, { useEffect, useState } from "react";
import axiosHandler from "../../api/axios";
import "./UserFormDownload.css";
// import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import {
  createdAt,
  set_id,
  setLabAddress,
  setLabContact,
  setLabContactNo,
  setLabEmail,
  setLabGstin,
  setLabNabl,
  setLabName,
  setPaymentMethod,
  setRound,
  setIsPrivate
} from "../../redux/userSclice";
import { Navigate } from "react-router-dom";
import { writeToJsonExcel} from '../trial/Trial'

function UserFormDownload({ year }) {
  const [years, setYears] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
        try {
          if(!year){
            return
          }
            if(year.toString().length === 4){
              const response = await axiosHandler.post("/sendUsers", { year });
              console.log("Responce is ",response)

              if (response.data && response.data.data && response.data.data.length !== 0) {
                  setYears(response.data.data);
                } else {
                    setYears([]); // Set empty array if no data
                }
            }
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 401) {
                    console.error("Unauthorized access - 401:", error.message);
                    // Handle 401 error, e.g., redirect to login, show a message, etc.
                    alert("Session expired. Please log in again.");
                    // Optionally, clear sensitive state data or navigate to login page
                } else {
                    console.error("Server responded with an error:", error.message);
                    alert(`Error: ${error.response.status} - ${error.message}`);
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received:", error.message);
                alert("No response from the server. Please check your network connection.");
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error in setting up request:", error.message);
                alert("An unexpected error occurred. Please try again.");
            }
            // Clear the state or handle as needed on error
            setYears([]);
        }
    }

    fetchData();
}, [year]);
  const handleButtonClick = (iteam) => {
    dispatch(setLabAddress(iteam.labAddress));
    dispatch(setLabName(iteam.labName));
    dispatch(setLabContactNo(iteam.labContactNo));
    dispatch(setLabEmail(iteam.labEmail));
    dispatch(setLabGstin(iteam.labGstin));
    dispatch(setLabContact(iteam.labContact));
    dispatch(setLabNabl(iteam.labNabl));
    dispatch(setRound(iteam.round));
    dispatch(set_id(iteam._id));
    dispatch(setPaymentMethod(iteam.paymentMethod));
    dispatch(createdAt(iteam.createdAt));
    dispatch(setIsPrivate(iteam.isPrivate));

    setRedirect(true);
  };

  function handleClick(){
    writeToJsonExcel(years)
  }
  return (
    <div className="w-full flex flex-col items-center mt-10">
      <button onClick={handleClick} className="border-2 h-full p-1 border-red-500  ml-4 bg-red-400 m-1 w-1/6 rounded" >Download CSV</button>
      <table className="w-11/12 border border-black">
        <thead>
          <tr className="color">
            <th className=" p-3 border border-black">LAB NAME</th>
            <th className=" border border-black">Contact Number</th>
            <th className=" border border-black">Registerd Date</th>
            <th className=" border border-black">Registration ID</th>
            <th className="w-2/12">Registration Form</th>
          </tr>
        </thead>
        {years.filter(item => item.isPrivate === true).map((iteam) => (
          <tbody key={iteam._id} className="color">
            <tr>

            <td className="border max-w-[19rem] border-black text-center text-lg font-bold ">
              {iteam.labName}
            </td>
            <td className="border border-black text-center text-sm  ">
              {iteam.labContactNo}
            </td>
            <td className="border border-black text-center text-sm  ">
              {new Date(iteam.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-")}
            </td>
            <td className="border border-black text-center text-sm  ">
              {iteam._id}
            </td>
            <td className="border border-black ">
              <button
                onClick={() => {
                  handleButtonClick(iteam);
                }}
                className="border-2 h-full border-green-500 ml-4 bg-green-400 m-1 w-5/6 p-1 rounded "
                >
                Download Form
              </button>
            </td>
          </tr>
                </tbody>
        ))}
      </table>
      {redirect && <Navigate to={"/APTlabUserPannel/download"} />}
    </div>
  );
}

export default UserFormDownload;
