// import React, { useState } from "react";
// import "./FeedbackForm.css";

// const FeedbackForm = () => {
//   const [rating, setRating] = useState(null);
//   const [feedback, setFeedback] = useState("");
//   const [submitted, setSubmitted] = useState(false);

//   const emojis = [
//     { label: 2, emoji: "😠" },
//     { label: 4, emoji: "😞" },
//     { label: 6, emoji: "😐" },
//     { label: 8, emoji: "😊" },
//     { label: 10, emoji: "🤩" },
//   ];
//   const meetingPtReq = [
//     {
//       uniqueID: 1,
//       question: "How do you rate the PT Matrix?",
//     },
//     {
//       uniqueID: 2,
//       question: "Meeting PT requirements",
//     },
//     {
//       uniqueID: 3,
//       question: "Condition/Packaging of PT sample received",
//     },
//     {
//       uniqueID: 4,
//       question: "On time Delivery of PT Report",
//     },
//     {
//       uniqueID: 5,
//       question: "Content of PT report useful and easy to understand",
//     },
//   ];
//   const overallSatisfaction = [
//     {
//       uniqueID: 6,
//       question: "Timely Resolution of your query if any",
//     },
//   ];
//   const service = [
//     {
//       uniqueID: 7,
//       question: "How do you rate the Overall QUALITY of our Service",
//     },
//   ];

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Rating:", rating);
//     console.log("Feedback:", feedback);
//     setSubmitted(true);
//   };

//   return (
//     <>
//       {submitted ? (
//         <div className="thank-you-message">
//           <h2>Thank you for your feedback! 🎉</h2>
//         </div>
//       ) : (
//         <>
//           <div className="feedback-form-container ">
//             <form onSubmit={handleSubmit} className="feedback-form p-1">
//               <div className="flex justify-around p-4 mb-10 gap-8">
//                 <div className="flex flex-col  w-full">
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Name of the Laboratory:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="PT Round Number:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Matrix / Product:"
//                   />
//                 </div>
//                 <div className="flex flex-col justify-between w-full">
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Lab Code:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Date:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Contact Person Name:"
//                   />
//                 </div>
//               </div>
//               <div className="flex gap-4 justify-around">
//                 <div>
//                   <h2 className="font-bold text-xl">
//                     MEETING PT REQUIREMNETS:
//                   </h2>
//                   {meetingPtReq.map((item) => {
//                     return (
//                       <div key={item.uniqueID} className="flex flex-col gap-4 ">
//                         <p>{item.question}</p>
//                         <div>
//                           {emojis.map((item, index) => (
//                             <button
//                               key={index}
//                               type="button"
//                               className={`emoji-button ${
//                                 rating === item.label ? "selected" : ""
//                               }`}
//                               onClick={() => setRating(item.label)}
//                               aria-label={item.label}
//                             >
//                               {item.emoji}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//                 <div>
//                   <h2 className="font-bold text-xl">SERVICE:</h2>
//                   {service.map((item) => {
//                     return (
//                       <div
//                         key={item.uniqueID}
//                         className="flex flex-col gajustify-between"
//                       >
//                         <p>{item.question}</p>
//                         <div>
//                           {emojis.map((item, index) => (
//                             <button
//                               key={index}
//                               type="button"
//                               className={`emoji-button ${
//                                 rating === item.label ? "selected" : ""
//                               }`}
//                               onClick={() => setRating(item.label)}
//                               aria-label={item.label}
//                             >
//                               {item.emoji}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//                 <div>
//                   <h2 className="font-bold text-xl">OVERALL SATISFACTION:</h2>
//                   {overallSatisfaction.map((item) => {
//                     return (
//                       <div
//                         key={item.uniqueID}
//                         className="flex flex-col gap-4 justify-between"
//                       >
//                         <p>{item.question}</p>
//                         <div>
//                           {emojis.map((item, index) => (
//                             <button
//                               key={index}
//                               type="button"
//                               className={`emoji-button ${
//                                 rating === item.label ? "selected" : ""
//                               }`}
//                               onClick={() => setRating(item.label)}
//                               aria-label={item.label}
//                             >
//                               {item.emoji}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>

//               <textarea
//                 placeholder="Tell us more about your experience..."
//                 value={feedback}
//                 onChange={(e) => setFeedback(e.target.value)}
//                 className="feedback-textarea"
//               />

//               <button type="submit" className="submit-button">
//                 Submit Feedback
//               </button>
//             </form>
//           </div>
//         </>
//       )}
//     </>
//   );
// };

// export default FeedbackForm;

import React, { useState } from "react";
import "./FeedbackForm.css";
import axios from "../../api/axiosPublic";

const FeedbackForm = () => {
  const [rating, setRating] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
  });
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [suggestions, setSuggestions] = useState({});
  const [labInfo, setLabInfo] = useState({
    labName: "",
    labCode: "",
    date: "",
    ptRound: "",
    matrix: "",
    contactPerson: "",
  });

  const emojis = [
    { label: 0, emoji: "😞" },
    { label: 5, emoji: "😊" },
    { label: 10, emoji: "🤩" },
  ];

  const meetingPtReq = [
    { uniqueID: 1, question: "How do you rate the PT Matrix?" },
    { uniqueID: 2, question: "Meeting PT requirements" },
    { uniqueID: 3, question: "Condition/Packaging of PT sample received" },
    { uniqueID: 4, question: "On time Delivery of PT Report" },
    {
      uniqueID: 5,
      question: "Content of PT report useful and easy to understand",
    },
  ];

  const overallSatisfaction = [
    { uniqueID: 6, question: "Timely Resolution of your query if any" },
  ];

  const service = [
    {
      uniqueID: 7,
      question: "How do you rate the Overall QUALITY of our Service",
    },
  ];

  const handleRatingChange = (id, value) => {
    setRating((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!labInfo.labName.trim()) newErrors.labName = "Lab Name is required.";
    if (!labInfo.labCode.trim()) newErrors.labCode = "Lab Code is required.";
    if (!labInfo.ptRound.trim())
      newErrors.ptRound = "PT Round Number is required.";
    if (!labInfo.matrix.trim())
      newErrors.matrix = "Matrix/Product is required.";
    if (!labInfo.contactPerson.trim())
      newErrors.contactPerson = "Contact Person Name is required.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({}); // Clear errors if validation passes

    const allInfo = {
      survay: [
        {
          formQuestion1: rating["1"].toString(),
          suggestions1: suggestions["1"],
        },
        {
          formQuestion2: rating["2"].toString(),
          suggestions1: suggestions["2"],
        },
        {
          formQuestion3: rating["3"].toString(),
          suggestions1: suggestions["3"],
        },
        {
          formQuestion4: rating["4"].toString(),
          suggestions1: suggestions["4"],
        },
        {
          formQuestion5: rating["5"].toString(),
          suggestions1: suggestions["5"],
        },
        {
          formQuestion6: rating["6"].toString(),
          suggestions1: suggestions["6"],
        },
        {
          formQuestion7: rating["7"].toString(),
          suggestions1: suggestions["7"],
        },
      ],
      feedback,
      ...labInfo,
    };
    console.log(suggestions);

    try {
      const someval = await axios.post("/cssform", allInfo);
      console.log("Ratings:", someval);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="flex w-[700px]">
          <img src="./PT-logo.png" className="h-20" alt="pt" />

          <div className="w-full">
            <h1 className="text-2xl text-center font-bold">
              Aashvi Proficiency Testing and Analytical Services LLP
            </h1>
            <p className="text-center mt-2 pb-2 font-medium">
              1-19-66/68/1, Brindavan Colony, Near Dr. A.S.Rao Nagar,
              Hyderabad-500062 email: info@aashvipt.com; website:
              www.aashvipt.com; Contact: 040-27148649
            </p>
          </div>
        </div>
      </div>
      {submitted ? (
        <div className="thank-you-message">
          <h2>Thank you for your feedback! 🎉</h2>
        </div>
      ) : (
        <div className="feedback-form-container">
          <h2 className="font-bold text-center text-2xl">
            CUSTOMER SATISFACTION SURVAY FORM
          </h2>
          <form onSubmit={handleSubmit} className="feedback-form">
            <table className="exclude-styles table-auto w-full border-collapse border border-black">
              <tbody>
                <tr>
                  <td className="border border-black p-1">
                    Name of the Laboratory:
                  </td>
                  <td className="border border-black p-1">
                    <input
                      type="text"
                      className="border-2 border-black rounded-md p-1 w-full"
                      placeholder="Name of the Laboratory:"
                      onChange={(e) =>
                        setLabInfo({ ...labInfo, labName: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-1">PT Round Number:</td>
                  <td className="border border-black p-1">
                    <input
                      type="text"
                      className="border-2 border-black rounded-md p-1 w-full"
                      onChange={(e) =>
                        setLabInfo({ ...labInfo, ptRound: e.target.value })
                      }
                      placeholder="PT Round Number:"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-1">Matrix / Product:</td>
                  <td className="border border-black p-1">
                    <input
                      type="text"
                      className="border-2 border-black rounded-md p-1 w-full"
                      placeholder="Matrix / Product:"
                      onChange={(e) =>
                        setLabInfo({ ...labInfo, matrix: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-1">Lab Code:</td>
                  <td className="border border-black p-1">
                    <input
                      type="text"
                      className="border-2 border-black rounded-md p-1 w-full"
                      placeholder="Lab Code:"
                      onChange={(e) =>
                        setLabInfo({ ...labInfo, labCode: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border border-black p-1">
                    Contact Person Name:
                  </td>
                  <td className="border border-black p-1">
                    <input
                      type="text"
                      className="border-2 border-black rounded-md p-1 w-full"
                      placeholder="Contact Person Name:"
                      onChange={(e) =>
                        setLabInfo({
                          ...labInfo,
                          contactPerson: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="feedback-sections">
              {[
                { title: "MEETING PT REQUIREMENTS", items: meetingPtReq },
                { title: "SERVICE", items: service },
                { title: "OVERALL SATISFACTION", items: overallSatisfaction },
              ].map((section) => (
                <table
                  key={section.title}
                  className="exclude-styles feedback-table w-full border-collapse border border-black"
                >
                  <thead>
                    <tr>
                      <th
                        className="border border-black p-1 text-left"
                        colSpan="3"
                      >
                        <h2 className="font-bold text-xl">{section.title}:</h2>
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-black p-1 text-left">
                        Question
                      </th>
                      <th
                        className="border border-black p-1 text-center"
                        colSpan="3"
                      >
                        Rating
                      </th>
                      <th className="border border-black p-1 text-left">
                        Suggestions
                      </th>
                    </tr>
                    <tr>
                      <th className="border border-black p-1 text-left"></th>
                      <th className="border border-black p-1 text-center">
                        EXCELLENT
                      </th>
                      <th className="border border-black p-1 text-center">
                        GOOD
                      </th>
                      <th className="border border-black p-1 text-center">
                        UNSATISFACTORY
                      </th>
                      <th className="border border-black p-1 text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.items.map((item) => (
                      <tr key={item.uniqueID} className="feedback-row">
                        <td className="border border-black p-1">
                          {item.question}
                        </td>
                        {emojis.map((emoji) => (
                          <td
                            key={emoji.label}
                            className="border border-black p-1 text-center"
                          >
                            <button
                              type="button"
                              className={`emoji-button ${
                                rating[item.uniqueID] === emoji.label
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleRatingChange(item.uniqueID, emoji.label)
                              }
                              aria-label={emoji.label}
                            >
                              {emoji.emoji}
                            </button>
                          </td>
                        ))}
                        <td className="border border-black p-1">
                          <input
                            type="text"
                            placeholder="Your suggestion..."
                            className="border border-gray-300 rounded-md p-1 w-full"
                            onChange={(e) =>
                              setSuggestions((prev) => ({
                                ...prev,
                                [item.uniqueID]: e.target.value,
                              }))
                            }
                          />
                        </td>
                      </tr>
                    ))}

                    {/* New row for future PT requirements */}
                    <tr>
                      <td className="border border-black p-1" colSpan="2">
                        Your future PT requirements & Matrices:
                      </td>
                      <td className="border border-black p-1" colSpan="3">
                        <textarea
                          placeholder="Your future PT requirements & Matrices:"
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                          className="border border-gray-300 rounded-md p-1 w-full feedback-textarea"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>

            <button type="submit" className="submit-button">
              Submit Feedback
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;
