import React, { useState } from 'react'
// import axios from '../../api/axiosPublic.js'
// import { useLocation } from 'react-router-dom'
// import RegistrationForm from './RegistrationForm.jsx'
import UserFormDownload from './UserFormDownload.jsx'

function Registration() {
 
    const [year, setYear] = useState(new Date().getFullYear());
    


  

    // console.log(location.pathname)
    
  return (
    <div className=''>
      <div className='flex justify-center'>
      <strong className='font-bold text-xl'>
        {"User Register In Year"} 
      </strong>
      
    <form >
        <input type="text" className='ml-2 w-16 font-bold text-red-500 text-lg border border-black rounded pl-1 pr-1' defaultValue={new Date().getFullYear()} placeholder='Year' onChange={e =>{setYear(e.target.value)}} />
    </form>
            </div>

        {<UserFormDownload year={year}/>}
    </div>
      
    
  )
}

export default Registration
