// import React, { useRef, useState, useEffect } from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import { useSelector, useDispatch } from "react-redux";
// import { resetUser } from "../../redux/userSclice";

// function Trial({ preview }) {
//   let user = useSelector((state) => state.user);
//   const dispatch = useDispatch();
//   const [renderCount, setRenderCount] = useState(0);
//   // const [isSubmitting, setIsSubmitting] = useState(false);
//   // const [isDownloaded, ] = useState(false); // State to track if PDF has been downloaded
//   const iframeRef = useRef(null);

//    const handleGeneratePDF = async () => {
//     // setIsSubmitting(true);

//     try {
//       if (!iframeRef.current || !iframeRef.current.contentWindow) {
//         throw new Error("Iframe not loaded or contentWindow not accessible");
//       }

//       const iframeDocument = iframeRef.current.contentWindow.document;
//       const receiptWrap1 = iframeDocument.querySelector("#body1");
//       const receiptWrap2 = iframeDocument.querySelector("#body2");

//       // Populate the fields with user data
//       iframeDocument.querySelector("#labName").innerHTML = user.labName;
//       iframeDocument.querySelector("#labAddress").innerHTML = user.labAddress;
//       iframeDocument.querySelector("#registration-date").innerHTML = new Date(user.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-");
//       iframeDocument.querySelector("#registration-id").innerHTML = user._id;
//       iframeDocument.querySelector("#labContact").innerHTML = user.labContact;
//       iframeDocument.querySelector(".labContactt").innerHTML = user.labContact;
//       iframeDocument.querySelector("#labContactNumber").innerHTML = user.labContactNo;
//       iframeDocument.querySelector("#labEmail").innerHTML = user.labEmail;
//       iframeDocument.querySelector("#labGstin").innerHTML = user.labGstin;
//       iframeDocument.querySelector("#labNabl").innerHTML = user.labNabl;

//       // Populate rounds
//       const roundsHTML = user.round.map((item, index) => `
//         <tr key=${index} class="border border-black">
//           <td class="pb-3 pl-3 border border-black text-sm font-bold">${item.roundName}</td>
//           <td class="pb-3 pl-3 border border-black text-sm ">${item.roundMatrix}</td>
//           <td class="pb-3 pl-3 border border-black text-sm ">${item.roundPrice} + 18% GST</td>
//         </tr>
//       `).join('');

//       iframeDocument.querySelector("#rounds1").innerHTML = `
//         <table class="border w-full gap-1 border-collapse mt-4">
//           <thead>
//             <tr class="border border-black">
//               <th class="pb-3 pl-3 border border-black text-sm font-bold"> PT Round Nos.</th>
//               <th class="pb-3 pl-3 border border-black text-sm font-bold">Matrix / Product</th>
//               <th class="pb-3 pl-3 border border-black text-sm font-bold">Advance Fee Amount + applicable Taxes paid, Rs</th>
//             </tr>
//           </thead>
//           <tbody>
//             ${roundsHTML}
//           </tbody>
//           <tfoot> 
//             <tr>
//               <td class="pb-3 pl-3 border-b  border-black border-l border-r text-sm font-bold">Total Amount</td>
//               <td class="pb-3 pl-3border-t border-b  border-black text-sm font-bold"></td>
//               <td class="pb-3 pl-3  border-b  border-black border-r text-sm font-bold">${user.round.reduce(
//                 (acc, round) => acc + parseInt(round.roundPrice),
//                 0
//               )} + 18% GST</td>
//             </tr>
//           </tfoot>
//         </table>
//       `;

//       const canvas1 = await html2canvas(receiptWrap1, {
//         scrollY: -window.scrollY,
//         scale: 1.25,
//         dpi: 72,
//         useCORS: true,
//         logging: true,
//       });

//       const imgData1 = canvas1.toDataURL("image/png", 0.65);
//       const imgWidth = 211;
//       const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;

//       const pdf = new jsPDF({
//         orientation: "portrait",
//         unit: "mm",
//         format: "a4",
//       });

//       pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);

//       const canvas2 = await html2canvas(receiptWrap2, {
//         scrollY: -window.scrollY,
//         scale: 1.25,
//         dpi: 72,
//         useCORS: true,
//         logging: true,
//       });

//       const imgData2 = canvas2.toDataURL("image/png", 0.65);
//       const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;

//       pdf.addPage();
//       pdf.addImage(imgData2, "PNG", 0, 0, imgWidth, imgHeight2, "FAST");

//       pdf.save("Test.pdf");
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     } finally {
//       // setIsSubmitting(false);
//         // Mark the PDF as downloaded
//       dispatch(resetUser());
//     }
//   }
 

//   useEffect(() => {
//     setRenderCount((prevCount) => prevCount + 1);  // Increment the render count
//     localStorage.removeItem("userData")
//   }, []);
  
//   useEffect(() => {
//     if (renderCount === 2) {
//       setTimeout(() => {
//         localStorage.removeItem("userData")
//         handleGeneratePDF();  // Trigger PDF generation on the second render
//       }, 1000);
//     }
//   }, [renderCount]);

//   return (
//     <div className="container h-full mx-auto p-4 flex flex-col items-center">
//       <div className="w-full">
//         <h2 className="text-2xl font-bold mb-4">
//           Form successfully submitted. To submit payment details or for any further enquiries, send us an email: <a href="mailto:info@aashvipt.com" className="text-blue-500">info@aashvipt.com</a>
//         </h2>
//         <iframe
//           ref={iframeRef}
//           src="/text.html"
//           title="Receipt Iframe"
//           className="sr-only"
//           style={{
//             height: "550px",
//             width: "850px",
//             backgroundColor: "transparent",
//           }}
//         ></iframe>
//       </div>
//     </div>
//   );
// }

// export default Trial;












import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../../redux/userSclice";
import { useLocation, useNavigate } from "react-router-dom";
function Trial() {
  let user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const location = useLocation()
  const navigate = useNavigate()
  const iframeRef = useRef(null);
  useEffect(()=>{
    localStorage.removeItem("userData")
    setTimeout(() => {
      localStorage.removeItem("userData")
      
    }, 1000);
  },[])

   const handleGeneratePDF = async () => {
    // setIsSubmitting(true);
    localStorage.removeItem("userData")
    try {
      if (!iframeRef.current || !iframeRef.current.contentWindow) {
        throw new Error("Iframe not loaded or contentWindow not accessible");
      }

      const iframeDocument = iframeRef.current.contentWindow.document;
      const receiptWrap1 = iframeDocument.querySelector("#body1");
      const receiptWrap2 = iframeDocument.querySelector("#body2");
      

      // Populate the fields with user data
      if(!!user.isPrivate){
        iframeDocument.querySelector("#labName").innerHTML = user.labName;
        iframeDocument.querySelector("#labAddress").innerHTML = user.labAddress;
        iframeDocument.querySelector("#registration-date").innerHTML = new Date(user.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-");
        iframeDocument.querySelector("#registration-id").innerHTML = user._id;
        iframeDocument.querySelector("#labContact").innerHTML = user.labContact;
        iframeDocument.querySelector(".labContactt").innerHTML = user.labContact;
        iframeDocument.querySelector("#labContactNumber").innerHTML = user.labContactNo;
        iframeDocument.querySelector("#labGstin").innerHTML = user.labGstin;
        iframeDocument.querySelector("#round-number").innerHTML = user.round.length;

      }else{
        iframeDocument.querySelector("#labName").innerHTML = user.labName;
        iframeDocument.querySelector("#labAddress").innerHTML = user.labAddress;
        iframeDocument.querySelector("#registration-date").innerHTML = new Date(user.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-");
        iframeDocument.querySelector("#registration-id").innerHTML = user._id;
        iframeDocument.querySelector("#labContact").innerHTML = user.labContact;
        iframeDocument.querySelector(".labContactt").innerHTML = user.labContact;
        iframeDocument.querySelector("#labContactNumber").innerHTML = user.labContactNo;
        iframeDocument.querySelector("#labGstin").innerHTML = user.labGstin;
        iframeDocument.querySelector("#labEmail").innerHTML = user.labEmail;
        iframeDocument.querySelector("#labNabl").innerHTML = user.labNabl;
        iframeDocument.querySelector("#round-number").innerHTML = user.round.length;
      }
    

      const roundPrice = user.round.reduce(
        (acc, round) => acc + parseInt(round.roundPrice),
        0
      )
      iframeDocument.querySelector("#round-price").innerHTML = roundPrice;
      const taxAmount = (roundPrice * 18) / 100;
      iframeDocument.querySelector("#gst-amount").innerHTML = taxAmount;
      iframeDocument.querySelector("#payable-amount").innerHTML = taxAmount + roundPrice;
      

      // Populate rounds
      const firstChunkSize = 5;
    const maxChunkSize = 14;
    const chunks = [];

    // Create the first chunk with 15 elements
    if (user.round.length > 0) {
        chunks.push(user.round.slice(0, firstChunkSize));
    }

    // Create the rest of the chunks with 25 elements each
    let start = firstChunkSize;
    if(!user.isPrivate){
      while (start < user.round.length) {
          const end = Math.min(start + maxChunkSize, user.round.length);
          chunks.push(user.round.slice(start, end));
          start = end;
      }
  
        const roundsHTML = chunks[0].map((item, index) => `
          <tr key=${index} class="border border-black">
            <td class="pb-3 pl-3 w-1/4 border border-black text-sm font-bold">${item.roundName}</td>
            <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${item.roundMatrix}</td>
            <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${item.roundPrice} + 18% GST</td>
            <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${user.paymentMethod}</td>
          </tr>
        `).join('');
  
        iframeDocument.querySelector("#rounds1").innerHTML = `
          <table class="border w-full gap-1 border-collapse mt-4">
            <thead>
              <tr class="border border-black">
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold"> PT Round Nos.</th>
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Matrix / Product</th>
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Advance Fee Amount + applicable Taxes paid, Rs</th>
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Mode of payment (NEFT/ cheque) by UTR</th>
              </tr>
            </thead>
            <tbody>
              ${roundsHTML}
            </tbody>
            
          </table>
        `;

    }else{
      while (start < user.round.length) {
        const end = Math.min(start + maxChunkSize, user.round.length);
        chunks.push(user.round.slice(start, end));
        start = end;
    }

      const roundsHTML = chunks[0].map((item, index) => `
        <tr key=${index} class="border border-black">
          <td class="pb-3 pl-3 w-1/4 border border-black text-sm font-bold">${item.roundName}</td>
          <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${item.roundMatrix}</td>
          <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">1</td>
          <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${item.roundPrice} </td>
          <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${ (item.roundPrice * 18) / 100} </td>
          <td class="pb-3 pl-3 w-1/4 border border-black text-sm ">${parseFloat(item.roundPrice) +parseFloat((item.roundPrice * 18) / 100)}</td>
        </tr>
      `).join('');

      iframeDocument.querySelector("#rounds1").innerHTML = `
        <table class="border w-full gap-1 border-collapse mt-4">
          <thead>
            <tr class="border border-black">
              <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold"> PT Round Nos.</th>
              <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Matrix / Product</th>
              <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold"> Qty</th>
              <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Unit Value, in INR</th>
              <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">GST@18%</th>
              <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold"> Total payable, INR </th>
            </tr>
          </thead>
          <tbody>
            ${roundsHTML}
          </tbody>
          
        </table>
      `;
    }

      const canvas1 = await html2canvas(receiptWrap1, {
        scrollY: -window.scrollY,
        scale: 1.25,
        dpi: 72,
        useCORS: true,
        logging: true,
      });

      const imgData1 = canvas1.toDataURL("image/png", 0.65);
      const imgWidth = 211;
      const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);


      const addRoundsTable = async (roundsHTML, receiptWrap) => {
        iframeDocument.querySelector(receiptWrap).innerHTML = `
          <table class="border w-full gap-1 border-collapse mt-4">
            <thead>
              <tr class="border border-black">
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold"> PT Round Nos.</th>
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Matrix / Product</th>
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Advance Fee Amount + applicable Taxes paid, Rs</th>
                <th class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">Mode of payment (NEFT/ cheque) by UTR</th>
              </tr>
            </thead>
            <tbody>
              ${roundsHTML}
            </tbody>
            
          </table>
        `;
  
        const canvas = await html2canvas(iframeDocument.querySelector(receiptWrap), {
          scrollY: -window.scrollY,
          scale: 1.25,
          dpi: 72,
          useCORS: true,
          logging: true,
        });
  
        const imgData = canvas.toDataURL("image/png", 0.65);
        const imgWidth = 211;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      };
      // const imgWidth = 211;
      // First page
     
      
  
      // Additional pages for remaining chunks
      for (let i = 1; i < chunks.length; i++) {
        const roundsHTML = chunks[i].map((item, index) => `
          <tr key=${index} class="border border-black">
            <td class="pb-3 w-1/4 pl-3 border border-black text-sm font-bold">${item.roundName}</td>
            <td class="pb-3 w-1/4 pl-3 border border-black text-sm ">${item.roundMatrix}</td>
            <td class="pb-3 w-1/4 pl-3 border border-black text-sm ">${item.roundPrice} + 18% GST</td>
            <td class="pb-3 w-1/4 pl-3 border border-black text-sm ">${user.paymentMethod}</td>
          </tr>
        `).join('');
        await addRoundsTable(roundsHTML, `#body${String.fromCharCode(65 + i - 1)}`); // bodyA, bodyB, etc.
      }
  










      const canvas2 = await html2canvas(receiptWrap2, {
        scrollY: -window.scrollY,
        scale: 1.25,
        dpi: 72,
        useCORS: true,
        logging: true,
      });

      const imgData2 = canvas2.toDataURL("image/png", 0.65);
      const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;

      pdf.addPage();
      pdf.addImage(imgData2, "PNG", 0, 0, imgWidth, imgHeight2, "FAST");

      pdf.save("Test.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      // setIsSubmitting(false);
        // Mark the PDF as downloaded
      dispatch(resetUser());
      if(location.pathname === "/download"){
        navigate("/userregistration")
      }else{
        navigate("/APTlabUserPannel/userDownload")

      }

    }
  }
 
  
  


  return (
    <div className="container h-full mx-auto p-4 flex flex-col ">
      <div className="">
      <span className="font-bold text-xl p-2 pr-0">*</span><span className="font-bold underline text-xl p-2 pl-0">Important note to Participants:</span>
        <ol className="p-12 pb-3 pt-2 list-decimal space-y-2 text-lg">
          <li><strong className="underline">Download</strong>  the submitted PT registration form by clicking on the button provided below.</li>
          <li> Registration will be confirmed upon the receipt of your advance <strong className="underline">participation fee</strong>. Your Registration will be on hold until the payment is received.  </li>
          <li> Participants are requested to share the <span className="underline">payment details</span> (amount, date and UTR num) upon making the Payment, through e-mail on:  <a href="mailto:info@aashvipt.com" className="text-blue-500">info@aashvipt.com</a></li>
          <li>   PT registration cannot be cancelled on or after the PT round Registration closing date. No refunds will be issued in case of non-participation in registered PT rounds. <br /><span className="italic">*International Clients shall email us for a quote as the shipping fee is not included in the PT Calendar for International shipping.</span></li>
          <li> Any updates/corrections to the submitted form should be emailed to us before the registration closing dates. Changes cannot be updated if received later.</li>
          <li>Bank Account details are available on the registration form. For any queries regarding payment please feel free to contact: Accounts Manager @ Email: <a href="mailto:info@aashvipt.com" className="text-blue-500">info@aashvipt.com</a>, or Contact : 040-27148649</li>
       
        </ol>
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        {/* <h2 className="text-4xl font-bold mb-4">
          Form successfully submitted. To submit payment details or for any further enquiries, send us an email: <a href="mailto:info@aashvipt.com" className="text-blue-500">info@aashvipt.com</a>
        </h2> */}

        <iframe
          ref={iframeRef}
          src={!!user.isPrivate?"/text2.html":"/text.html"}
          title="Receipt Iframe"
          className="sr-only"
          style={{
            height: "550px",
            width: "850px",
            backgroundColor: "transparent",
          }}
        ></iframe>
        <button className="text-2xl mt-6 Calender-table p-4 border-2 border-green-700 bg-green-500 w-2/6  font-bold rounded-md text-white" onClick={handleGeneratePDF}>Download Registration Form by Clicking here</button>
      </div>
    </div>
  );
}
export default Trial;

